<script>
  import { FCButton } from '@fc/angie-ui';

  export default {
    name: 'CartSubItemShopify',
    components: {
      FCButton,
    },
    props: {
      freeMembershipMonths: {
        type: Boolean,
        default: false,
      },
      isDrawer: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      subscription() {
        return {
          id: this.$store.getters.membershipInCart.variants[0].id,
          price: this.$store.getters.membershipInCart.variants[0].price,
          pricingDescription: this.$store.getters.membershipInCart.description,
          product: {},
          productName: this.$store.getters.membershipInCart.title,
          qty: 1,
          subid: this.$store.getters.membershipInCart.metadata.stripeSubId,
          type: this.$store.getters.membershipInCart.productType,
        };
      },
      enableRemove() {
        return !this.$store.getters.cartProducts.packageInCart;
      },
    },
  };
</script>

<template>
  <div class="cart-sub-item fc-flex fc-justify-start fc-align-start fc-margin-y-4">
    <p class="cart-sub-item__qty fc-bold fc-margin-0 fc-font-size-5">
      {{ subscription.qty }}
    </p>
    <div class="cart-sub-item__info fc-flex fc-column fc-justify-start fc-padding-bottom-2 fc-text-left">
      <p class="fc-inline-block fc-margin-0 fc-bold fc-padding-bottom-2 fc-font-size-5 fc-text-blue">
        {{ subscription.productName }}
      </p>
      <p class="cart-sub-item__description fc-inline-block fc-text-blue fc-margin-0">
        <span v-if="subscription.price > 0">
          {{ subscription.price | currency }}/mo
        </span>
        <span>
          {{ subscription.pricingDescription }}
        </span>
      </p>
    </div>
    <div class="fc-flex fc-column fc-align-end">
      <p v-if="subscription.price > 0" class="fc-text fc-inline-block fc-text-right fc-uppercase fc-font-size-5">
        <span>{{ subscription.price | currency }}</span>
      </p>
      <FCButton
        v-if="enableRemove"
        button-theme="button-text"
        custom-event-name="removeItem"
        v-track-click="`${$options.name}_RemoveItem`"
        @removeItem="$emit('removeFromCart', subscription)">
        <p class="cart-item__remove-button fc-text fc-text-blue fc-underline fc-padding-left-1 fc-font-size-4">
          Remove
        </p>
      </FCButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cart-sub-item {
  width: get-block-size-level(full);

  &__qty {
    flex: 0 0 20px;

    @media #{$small-device-up} {
      flex: 0 0 40px;
    }
  }

  &__info {
    flex: 1 1 300px;
  }

  &__modal {
    font-size: 13px;
    cursor: pointer;
    vertical-align: bottom;
  }

  &__description {
    font-family: $font-stack-light;
    font-size: get-font-size-level(4);
    opacity: 0.73;

    @media #{$small-device-up} {
      font-size: get-font-size-level(5);
    }
  }
}
</style>
