<template>
  <div>
    <div
      class="payment-method-container"
      :class="{'payment-method-container-active': active }"
      v-track-click="`${$options.name}_${name}_Select`"
      @click="$emit('onSelected')">
      <div class="payment-method-block">
        <div class="payment-method-checkbox-container">
          <div class="payment-method-checkbox " :class="{'payment-method-checkbox-active': active }">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#ffffff"
              width="20"
              height="20"
              viewBox="0 0 24 24">
              <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
            </svg>
          </div>
        </div>
        <header
          class="payment-method-text">
          <slot name="header" />
        </header>
      </div>
    </div>
    <Collapsible :cid="name" :state="active">
      <div class="inner-block">
        <slot />
      </div>
    </Collapsible>
  </div>
</template>

<script>
  import Collapsible from '@/shared/components/CollapsiblePanel.vue';

  export default {
    name: 'Checkout_PaymentMethod',
    components: {
      Collapsible,
    },
    props: {
      active: Boolean,
      name: {
        type: String,
        default: '',
      },
    },
  };
</script>
<style scoped lang="scss">

  .payment-method-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid $color-dark-gray;
    border-radius: $input-radius;
    padding: 10px 20px;
    min-height: 75px;
    margin: 5px 0;
    cursor: pointer;

    @media #{$tablet} {
      padding: 10px 20px;
      margin: 5px 0;
    }

    @media #{$desktop} {
      padding: 10px 20px;
      margin: 5px 0;
    }
  }

  .payment-method-container-active {
    border: 2px solid #414141;
  }

  .payment-method-block {
    display: flex;
    flex-direction: row;
  }

  .payment-method-text {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
  }

  .payment-method-checkbox-container {
    flex: 0 0 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .payment-method-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    border: 2px solid $color-dark-gray;
    border-radius: 5px;
  }

  .payment-method-checkbox-active {
    border: 2px solid #414141;
    background-color: #414141;
  }

  .inner-block {
    padding: 10px 20px;
    //background-color: $color-light-gray;
    border-radius: 5px;
  }
</style>
