<template>
  <transition name="fade">
    <div class="checkout_form">
      <div class="checkout_form-email-container">
        <h3 id="shippingsection">
          Shipping Info
        </h3>
        <div class="checkout_row">
          <div class="checkout_firstname">
            <FormInput
              id="shippingFirstName"
              v-model="shippingFirstName"
              class="fc-input"
              type="text"
              name="firstName"
              autocomplete="given-name"
              title="First Name"
              :error="!shippingValid.firstName" />
          </div>
          <div class="checkout_lastname">
            <FormInput
              id="shippingLastName"
              v-model="shippingLastName"
              class="fc-input"
              type="text"
              name="lastName"
              autocomplete="family-name"
              title="Last Name"
              :error="!shippingValid.lastName" />
          </div>
        </div>

        <div class="checkout_row">
          <FormInput
            id="shippingAddress1"
            v-model="shippingAddress1"
            class="fc-input"
            type="text"
            name="shippingStreetAddress1"
            autocomplete="address-line1"
            title="Address Line 1"
            :error="!shippingValid.address" />
        </div>
        <div class="checkout_row">
          <FormInput
            id="shippingAddress2"
            v-model="shippingAddress2"
            type="text"
            class="fc-input"
            name="shippingStreetAddress2"
            autocomplete="address-line2"
            title="Address Line 2" />
        </div>
        <div class="checkout_row">
          <div class="checkout_city">
            <FormInput
              id="shippingCity"
              v-model="shippingCity"
              class="fc-input"
              type="text"
              name="shippingCity"
              autocomplete="address-level2"
              title="City"
              :error="!shippingValid.city" />
          </div>
        </div>
        <div class="checkout_row">
          <div class="checkout_country" :class="{ checkout_input_error: !shippingValid.country }">
            <FormSelect
              id="shippingCountry"
              v-model="shippingCountry"
              class="checkout_country_select fc-input"
              required
              :options="ISOCountryAlpha2"
              hint="Country" />
          </div>
          <div
            class="checkout_state"
            :class="{ checkout_input_error: !shippingValid.state }">
            <FormSelect
              id="shippingState"
              v-model="shippingState"
              name="shippingState"
              autocomplete="address-level1"
              class="checkout_country_select fc-input"
              required
              :options="isoStateAlpha2"
              :error="!shippingValid.state "
              hint="State/Province" />
          </div>
          <div class="checkout_zipcode">
            <FormInput
              id="shippingPostalCode"
              v-model="shippingPostalCode"
              class="fc-input"
              type="text"
              name="shippingPostalCode"
              title="Zip Code"
              autocomplete="postal-code"
              :error="!shippingValid.postalCode" />
          </div>
        </div>
      </div>
      <div v-if="isShippingError" class="checkout_error">
        <p>{{ shippingError.message || shippingError }}</p>
      </div>
    </div>
  </transition>
</template>

<script>
  import { mapGetters } from 'vuex';
  import isoCountryCode from '@/shared/datasets/iso_country_code.json';
  import isoStateCode from '@/shared/datasets/iso_state_code.json';
  import FormInput from '../FCInput.vue';
  import FormSelect from '../FCSelect.vue';
  import storeHelpers from '@/shared/store/helpers';

  export default {
    name: 'Checkout_Shipping',
    components: {
      FormInput,
      FormSelect,
    },
    data: () => ({
      ISOCountryAlpha2: isoCountryCode,
      isoStateAlpha2: [
        ...isoStateCode, 
        {
          "name": "Alberta",
          "key": "AB"
        },
        {
          "name": "British Columbia",
          "key": "BC"
        },
        {
          "name": "Ontario",
          "key": "ON"
        }
      ],
    }),
    computed: {
      ...mapGetters([
        'bundle',
        'shippingError',
        'hasRestrictedItemsInCart',
        'shippingRestricted',
        'checkFieldsNotFilled',
        'shipping',
        'shippingValid']),
      ...storeHelpers.mapInputBinding([
        'shippingFirstName',
        'shippingLastName',
        'shippingAddress1',
        'shippingAddress2',
        'shippingCity',
        'shippingPostalCode',
        'shippingCountry',
        'shippingState',
      ]),
      isShippingError() {
        return Object.keys(this.shippingError).length;
      },
    },
  };
</script>

<style scoped lang="scss">
  .fc-input {
    margin: 6px 0;
  }

  .checkout_form {
    padding: 8px;

    @media #{$tablet} {
      padding: 16px;
    }

    @media #{$desktop} {
      padding: 16px;
    }
  }

  .checkout_form-email-container {
    box-sizing: border-box;
    padding: 10px 0;
  }

  .checkout_form-email-container p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .checkout_form-email-container h3 {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 8px;
  }

  .checkout_form p {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .checkout_form h3 {
    padding-bottom: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .checkout_state,
  .checkout_country {
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    color: #000;
  }

  .checkout_country {
    display: flex;
    padding: 0;
  }

  .checkout_country_select {
    border: none;
    font-size: 16px;
    box-sizing: border-box;
    background-color: #fff;
    width: 100%;
    align-self: center;
    appearance: none;
    -webkit-appearance: none;
  }

  .checkout_country_select_disabled {
    background-color: $color_light_gray !important;
  }

  select {
    display: inline-block;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image:
      linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  }

  .checkout_terms {
    font-family: $font-stack-bold;
  }

  .checkout_error {
    color: $color-accent;
  }

  .checkout_firstname,
  .checkout_lastname,
  .checkout_city,
  .checkout_state,
  .checkout_phone,
  .checkout_country,
  .checkout_zipcode {
    width: 100%;
    position: relative;
  }

  .checkout_row {
    width: 100%;
    position: relative;
  }

  .checkout_text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .checkout__clickable {
    cursor: pointer;
  }

  a.checkout__clickable,
  p.checkout__clickable {
    text-decoration: underline;
    color: $color-accent;
  }

  @media all and (min-width: 700px) {
    .checkout_firstname {
      padding-right: 5px;
      width: 50%;
    }

    .checkout_lastname {
      margin-left: 5px;
      width: 50%;
    }

    .checkout_city {
      width: 100%;
    }

    .checkout_state {
      margin-left: 10px;
      width: 25%;
    }

    .checkout_zipcode {
      margin-left: 10px;
      width: 25%;
    }

    .checkout_row {
      display: flex;
      width: 100%;
    }

    .checkout_country {
      width: 50%;
    }
  }

  .checkout_processing {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .checkout_processing_item {
    padding-left: 10px;
  }

  .checkout_form input,
  .checkout_state input,
  .checkout_state input:focus,
  .checkout_state select,
  .checkout_country select {
    position: relative;
    width: 100%;
    border-radius: 50px;
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #e4e6e7;
    font-size: 16px;
    padding: 12px 42px 4px 18px;
    margin: 6px 0;
    color: #000;
    -webkit-appearance: none;
  }

  .checkout_form input:disabled {
    background-color: $color_light_gray;
  }

  .checkout_form input:focus {
    border: 1px solid #000;
  }

  .floating-label {
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 19px;
    transition: 0.2s ease all;
    color: #656565;
  }

  .checkout_input_error select {
    color: $color-accent;
    border-color: $color-accent;
  }

  input:disabled ~ .floating-label,
  select:disabled ~ .floating-label,
  select:focus ~ .floating-label,
  input:focus ~ .floating-label,
  select:not(:focus):valid ~ .floating-label,
  input:not(:focus):valid ~ .floating-label {
    top: 10px;
    bottom: 10px;
    font-size: 10px;
    opacity: 1;
  }

  .checkout_input_error {
    color: $color-accent;
  }

  .checkout_input_error select,
  .checkout_input_error select:focus {
    border-color: $color-accent;
    color: $color-accent;
  }
</style>
