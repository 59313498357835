<template>
  <a
    :class=" buttonStyle">
    <span
      v-show="state===0"><slot /></span>
    <div
      v-show="state===1"
      class="btn2_message">
      <font-awesome-icon
        :icon="['fas', 'circle-notch']"
        spin /> {{ loadingMsg }}
    </div>
    <div
      v-show="state===2"
      class="btn2_message">
      <font-awesome-icon :icon="['fas', 'check-circle']" />
      Done
    </div>
    <div
      v-show="state===3"
      class="btn2_message">
      <font-awesome-icon :icon="['fas', 'times-circle']" /> Failed
    </div>
  </a>
</template>
<script>
  import buttonStyleMixin from '../mixins/button-style';

  export default {
    name: 'ButtonAsyncState',
    mixins: [buttonStyleMixin],
    props: {
      state: {
        type: Number,
        default: 0,
      },
      title: {
        type: String,
        default: 'Start',
      },
      loadingMsg: {
        type: String,
        default: 'Processing...',
      },
      successMsg: {
        type: String,
        default: 'Done',
      },
      failedMsg: {
        type: String,
        default: 'Failed',
      },
    },
  };

</script>

<style scoped lang="scss">

</style>
