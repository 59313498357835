<template>
  <div class="checkout-shipppingmodal">
    <div class="checkout-shipppingmodal-inner">
      <div class="checkout-shipppingmodal-block">
        <h2 class="modal-title" v-track-view="$options.name">
          Standard Shipping
        </h2>
        <ul class="checkout-shipppingmodal-list">
          <li> Free shipping </li>
          <li> Arrives in 7-10 days </li>
          <li> No presence needed for delivery </li>
          <li> Assembly required </li>
        </ul>
        <p>Update: Due to high sales volume, shipping time may take up to 15 days</p>
      </div>
      <div v-if="eligible" class="checkout-shipppingmodal-block-premium">
        <h2 class="modal-title" v-track-view="$options.name">
          Premium Delivery
        </h2>
        <ul class="checkout-shipppingmodal-list">
          <li> Schedule delivery in 6-10 business days </li>
          <li> Fully assembled in your home </li>
          <li> Base pre-filled with 300lb sand </li>
          <li> Experts installation during weekdays </li>
        </ul>
        <ButtonAction
          class="checkout-shipppingmodal-button"
          secondary
          dark
          @click.native="selectMethod('premiumDelivery')">
          Select Premium
        </ButtonAction>
      </div>
      <div v-else class="checkout-shipppingmodal-block-picture">
        <div class="checkout-shipppingmodal-overlay" />
      </div>
    </div>

    <div
      class="close-button"
      v-track-click="`${id}_CloseIcon`"
      @click="$emit('close')">
      <font-awesome-icon :icon="['fas', 'times']" />
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import ButtonAction from '@/shared/components/ButtonAction.vue';

  export default {
    name: 'Checkout_ShippingModal',
    components: {
      ButtonAction,
    },
    props: {
      id: { type: String, default: '' },
    },

    computed: {
      ...mapGetters(['order', 'processingTotalCost']),
      eligible() {
        return this.order ? this.order.eligibleDeliveright : false;
      },
    },
    methods: {
      selectMethod(method) {
        if (this.processingTotalCost) {
          return;
        }
        this.$store.dispatch('setShippingMethod', method);
        this.$emit('close');
      },
    },
  };
</script>

<style scoped lang="scss">

  .checkout-shipppingmodal {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;

    @media #{$tablet} {
      background-image: none;
    }

    @media #{$desktop} {
      background-image: none;
    }

    &-inner {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: stretch;
      flex-wrap: wrap;
      width: 100%;
    }

    &-block-premium,
    &-block {
      flex: 1 1 350px;
      display: flex;
      flex-direction: column;
      color: $color-dark-gray-1;
      padding: 20px;

      @media #{$iphone-5} {
        padding: 10px;
      }

      @media #{$tablet} {
        padding: 20px 20px;
      }

      @media #{$desktop} {
        padding: 20px 40px;
      }
    }

    &-block-premium {
      //border-left: 1px solid $color-dark-gray;
      background-color: $color-dark-gray-1;
      color: white;
    }

    &-button {
      margin: 10px 20px;
    }

    &-block-picture {
      flex: 1 1 350px;
      background-image: url('https://cdn3.joinfightcamp.com/1000x0/v4/fc-shop-gym-setup-nyc-001.jpg');
      background-size: cover;
      background-position: 80% 50%;
      display: none;

      @media #{$tablet} {
        display: flex;
      }

      @media #{$desktop} {
        display: flex;
      }
    }

    &-overlay {
      height: 100%;
      width: 20%;
      background-image: linear-gradient(to right, white, rgba(255, 255, 255, 0));
    }

    &-list {
      flex: 1 1 auto;
      margin: 0;
      padding: 0 0 0 30px;
    }
  }

  .modal-title {
    margin: 20px 0 10px 0;
    letter-spacing: 1px;
  }

  .modal-text {
    margin: 0;
  }

  .modal-text2 {
    margin-bottom: 20px;
  }

  .modal-input {
    max-width: 375px;
    margin: 0 0;
  }

  .modal-close-button {
    font-size: 14px;
    color: white;
    margin: 20px;

    @media #{$tablet} {
      margin: 0 0 20px;
    }

    @media #{$desktop} {
      margin: 0 0 20px;
    }
  }

  .modal-close-button2 {
    font-size: 14px;
    color: white;
    margin: 20px;

    @media #{$tablet} {
      margin: 20px 0 20px;
    }

    @media #{$desktop} {
      margin: 20px 0 20px;
    }
  }

  .button-cta {
    flex: 1 0 auto;
    margin: 10px 0;
    width: 100%;
    max-width: 375px;
  }

  .modal-input-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px;
    flex-wrap: wrap;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    font-size: 20px;
    color: $color-dark-gray-1;

    @media #{$tablet} {
      color: white;
    }

    @media #{$desktop} {
      color: white;
    }
  }

  .modal-input-error {
    color: $color-accent;
    font-size: 14px;
    margin: 5px 0 0;
  }
</style>
