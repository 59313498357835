<template>
  <div
    :id="id"
    class="payment-method-container"
    :class="{'payment-method-container-active': active }"
    v-track-click="`${$options.name}_${id}_Select`"
    @click="$emit('onSelected')">
    <div class="payment-method-block">
      <div class="payment-method-text">
        <span> {{ title }} </span>
        <span class="payment-method-period"> {{ subtitle }} </span>
      </div>
      <div class="payment-method-price">
        <span v-if="price === 'Free' && showDiscount" class="payment-method-shipping"><s>$250</s></span><span>{{ price }}</span>
      </div>
      <div class="payment-method-checkbox-container">
        <div class="payment-method-checkbox " :class="{'payment-method-checkbox-active': active }">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#ffffff"
            width="20"
            height="20"
            viewBox="0 0 24 24">
            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Checkout_ShippingMethodBlock',
    props: {
      active: Boolean,
      id: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      subtitle: {
        type: String,
        default: '',
      },
      price: {
        type: String,
        default: '',
      },
      showDiscount: {
        type: Boolean,
        default: true,
      },
    },
  };

</script>
<style scoped lang="scss">
  .payment-method {
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 2px solid $color-dark-gray;
      border-radius: $input-radius;
      padding: 10px 20px;
      height: 70px;
      margin: 5px 0;
      cursor: pointer;

      @media #{$iphone-5} {
        padding: 10px;
      }

      @media #{$tablet-up} {
        padding: 10px 20px;
        margin: 5px 0;
      }
    }

    &-container-active {
      border: 2px solid #414141;
    }

    &-block {
      display: flex;
      flex-direction: row;
    }

    &-text {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      font-size: 14px;

      @media #{$tablet-up} {
        font-size: 16px;
      }
    }

    &-period {
      color: $color-dark-gray-1;
    }

    &-price {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 0 0 90px;
      font-family: $font-stack-bold;
    }

    &-shipping {
      margin-right: 5px;
    }

    &-checkbox-container {
      flex: 0 0 25px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      width: 25px;
      border: 2px solid $color-dark-gray;
      border-radius: 5px;
    }

    &-checkbox-active {
      border: 2px solid #414141;
      background-color: #414141;
    }
  }

</style>
