<template>
  <div
    id="checkout_cart"
    class="checkout"
    :class="{ checkout_container__active: true}">
    <div class="checkout-row">
      <div class="checkout-dynamic">
        <Email />
        <div class="fc-padding-x-4">
          <FCInput
            v-model="receiptEmail"
            type="email"
            title="Company/Partner Email"
            name="receiptEmail"
            :value="receiptEmail" />
        </div>
        <Shipping />
        <ShippingMethods :method="shippingMethod" />
        <MembershipTypes v-if="isSubsInCart" />
        <Payment
          :payment-methods="paymentMethods"
          @onSubmit="onSubmit($event)"
          @transactionCompleted="completed()" />
      </div>
      <div class="checkout-summary">
        <div class="checkout-summary-panel">
          <h2 class="checkout-summary-title">
            Order Summary
          </h2>
          <Cart
            enable-remove
            class="checkout-summary-cart"
            :enable-affirm="isEquipInCart"
            :enable-promo="isEquipInCart" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import debounce from 'lodash/debounce';
  import { mapGetters } from 'vuex';
  import { FCInput } from '@fc/angie-ui';
  import storeHelpers from '@/shared/store/helpers';
  import Cart from '@/shared/components/cart/CartFrame.vue';
  import Email from '@/shared/components/checkout/Checkout_Email.vue';
  import Shipping from '@/shared/components/checkout/Checkout_Shipping.vue';
  import Payment from '@/shared/components/checkout/Checkout_Payment.vue';
  import PaymentMethodCreditCard from '@/shared/components/checkout/Checkout_PaymentMethodCreditCard.vue';
  import PaymentMethodPrepayAffirmTeleSale from '@/shared/components/checkout/Checkout_PaymentMethodAffirmTeleSale.vue';
  import ShippingMethods from '@/shared/components/checkout/Checkout_ShippingMethods.vue';
  import MembershipTypes from '@/shared/components/MembershipTypes.vue';
  import { ShippingMethodTypes } from '@/shared/constants/ShippingMethodTypes';

  export default {
    name: 'CheckoutForm',
    components: {
      Cart,
      Shipping,
      Payment,
      Email,
      ShippingMethods,
      MembershipTypes,
      FCInput,
    },
    data: () => ({
      paymentMethods: [
        {
          cid: 'creditcard_cid',
          component: PaymentMethodCreditCard,
          merchant: 'stripe',
        },
        {
          cid: 'prepay_affirm_cid',
          component: PaymentMethodPrepayAffirmTeleSale,
          merchant: 'affirm',
        },
      ],
    }),
    computed: {
      ...mapGetters([
        'isEquipInCart',
        'isSubsInCart',
        'order',
        'bundle',
        'cartqty',
        'cartSyncCompleted',
        'cartDrawerOpen',
        'processingTotalCost',
        'shipping',
        'checkFieldsNotFilled',
        'isShippingFilled',
        'isContactFilled',
        'cartCoupon',
        'hasPackageWithBag',
      ]),
      ...storeHelpers.mapInputBinding(['receiptEmail']),
      checkFormFilled() {
        return this.isShippingFilled && this.isContactFilled;
      },
      shippingMethod() {
        return ShippingMethodTypes.STANDARD;
      },
    },
    methods: {
      completed() {
        this.$store.commit('clearShipping');
        this.$store.commit('clearContact');
        this.$router.push({ path: '/bizDev/confirmation/' });
      },
      async onSubmit(handler) {
        this.filledOnce = true;
        if (!handler) {
          return;
        }
        if (this.order) {
          const shippingMethodError = await this.$store.dispatch('validateShippingMethod');
          if (shippingMethodError) {
            return;
          }

          await Promise.resolve(handler()).then(() => {
            this.$store.dispatch('sendReceiptEmail');
          }).catch((err) => {
            console.log(err);
          });
        } else if (!this.processingTotalCost) {
          await Promise.resolve(true)
            .then(this.processTotalCost)
            .catch((err) => {});
        }
      },

      async processTotalCost() {
        const contactError = await this.$store.dispatch('validateContact');
        const shippingError = await this.$store.dispatch('validateShipping');
        if (contactError) {
          return;
        }
        if (shippingError) {
          return;
        }

        const order = await this.$store.dispatch('createOrder', this.shipping);
        if (order) {
          this.$analytics.nameIdentified(this.shipping.firstName, this.shipping.lastName);
          this.$analytics.phoneNumberIdentified(this.shipping.phoneNumber);
        }
      },

      refreshForm() {
        if ((this.checkFormFilled || this.filledOnce) && this.cartqty > 0) {
          this.filledOnce = true;
          this.debounce();
        } else {
          this.$store.commit('updateOrder', null);
          this.$store.commit('clearShippingAddress');
        }
      },

      debounce: debounce(
        // eslint-disable-next-line func-names
        function () {
          this.$store.commit('clearShippingAddress');
          Promise.resolve()
            .then(this.processTotalCost)
            .catch((err) => {});
        },
        500,
      ),
    },
    watch: {
      shipping: {
        handler() {
          this.refreshForm();
        },
        deep: true,
      },
      contact: {
        handler() {
          this.refreshForm();
        },
        deep: true,
      },
      bundle: {
        handler() {
          this.refreshForm();
        },
        deep: true,
      },
      cartCoupon() {
        this.refreshForm();
      },
    },
    created() {
      this.$store.commit('closeDrawer');
    },

  };

</script>
<style scoped lang="scss">

  .checkout {
    &-cartbar {
      @media #{$desktop} {
        display: none;
      }
    }

    &-row {
      position: relative;
      display: flex;
      flex-direction: row;
      max-width: $page-width;
      margin: 20px auto;
      justify-content: center;
      align-items: flex-start;
    }

    &-processing {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-processing-title {
      margin: 10px;
    }

    &-dynamic {
      flex: 0 1 600px;
    }

    &-summary {
      display: none;
      flex: 1 1 600px;

      @media #{$desktop} {
        display: flex;
      }

      &-panel {
        width: 100%;
        text-align: center;
        color: $color-cart-text;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      &-title {
        margin: 30px 0 10px;
      }

      &-cart {
        max-width: 400px;
      }
    }

    &-cartdrawer {
      @media #{$desktop} {
        display: none;
      }
    }

    &::v-deep {
      .checkout__clickable {
        display: none;
      }
    }
  }

</style>
