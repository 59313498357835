<template>
  <PaymentMethod
    name="CreditCartMethod"
    :active="active"
    @onSelected="$emit('onSelected')">
    <div slot="header">
      <h3 class="title">
        Credit Card
      </h3>
    </div>
    <strong class="pm-title">
      Enter your card information
    </strong>
    <form id="payment-form2">
      <div class="checkout_form-creditcard-container" :class="{ checkout_disabled: disabled }">
        <div class="checkout_form-creditcard-elements">
          <div class="form-row">
            <div id="card-element2" />
            <div id="card-errors2" role="alert" />
          </div>
        </div>
      </div>
    </form>
    <div v-if="cardInfoError" class="checkout_error">
      <p>{{ errorMessage }}</p>
    </div>
    <div class="btn-row">
      <a class="change-method" @click="$emit('onSelected')">Change Payment Method</a>
      <div v-if="disabled || processingTotalCost" class="checkout_processing">
        <div class="checkout_processing_item">
          <font-awesome-icon :icon="['fas', 'circle-notch']" spin />
        </div>
        <h4 class="checkout_processing_item">
          PROCESSING...
        </h4>
      </div>
      <ButtonAction
        v-else
        primary
        class="btn"
        v-track-click="`${$options.name}_PlaceOrder`"
        @click.native="$emit('onSubmit', validatePayment)">
        Place Order
      </ButtonAction>
    </div>
    <FullScreenSpinner :open="processingPaymentMethod" />
  </PaymentMethod>
</template>

<script>
  import {
    mapGetters,
  } from 'vuex';
  import PaymentMethod from './Checkout_PaymentMethod.vue';
  import paymentHelper from './paymentHelper';
  import FullScreenSpinner from '@/shared/components/SpinnerFullScreen.vue';
  import ButtonAction from '@/shared/components/ButtonAction.vue';
  import EventBus from '@/shared/scripts/eventbus';

  export default {
    name: 'Checkout_PaymentMethodCreditCard',
    components: {
      PaymentMethod,
      FullScreenSpinner,
      ButtonAction,
    },
    props: {
      active: Boolean,
    },
    data: () => ({
      processingPaymentMethod: false,
      cardInfoError: '',
    }),
    computed: {
      ...mapGetters([
        'bundle',
        'processingTotalCost',
        'currentUser',
        'order',
        'confirmation',
        'isShopifyOn'
      ]),

      disabled() {
        return this.processingPaymentMethod;
      },
      errorMessage() {
        if (this.cardInfoError === 'Order is not payable') {
          return 'Something went wrong. This order has already been paid in Stripe. Please double check if order was created in Shopify. If not, reach out to Support to refund the order in Stripe, refresh this page, and start a new order.'
        }
        return this.cardInfoError;
      }
    },
    watch: {
      processingPaymentMethod(newval, oldval) {
        const body = document.getElementsByTagName('BODY')[0];
        if (this.open) {
          body.style.overflow = 'hidden';
        } else {
          body.style.overflow = 'scroll';
        }
      },
      currentUser() {
        if (!this.currentUser) {
          this.card.clear();
        }
      },
    },
    mounted() {
      const stripePublicKey = process.env.VUE_APP_STRIPE_KEY;
      this.stripe = window.Stripe(stripePublicKey);
      this.elements = this.stripe.elements();

      // Create an instance of the card Element
      this.card = this.elements.create('card', {
        style: {
          base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
          },
        },
      });

      // Handle real-time validation errors from the card Element.
      this.card.addEventListener('change', (event) => {
        const displayError = document.getElementById('card-errors2');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
        this.cardInfoValidated = false;
      });
      this.mounted = false;

      // Add an instance of the card Element into the `card-element` <div>
      if (window.affirm && window.affirm.ui) {
        window.affirm.ui.ready(() => {
          window.affirm.ui.refresh();
        });
      }
      if (!this.mounted) {
        this.mounted = true;
        this.card.mount('#card-element2');
        // Handle form submission
        const form = document.getElementById('payment-form');

        if (form) {
          form.addEventListener('submit', (event) => {
            event.preventDefault();
          });
        }
      }
    },
    methods: {

      async validatePayment() {
        this.processingPaymentMethod = true;
        this.cardInfoError = '';
        const { card } = this;
        const { stripe } = this;
        const store = this.$store;
        const apiClient = this.$api_client;
        const { bundle } = this;
        const method = 'creditcard';

        if (this.isShopifyOn) {
          const paymentMethodResult = await  this.stripe.createPaymentMethod({
            type: 'card',
            card,
          });
          if (paymentMethodResult.error) {
            // TODO handle card errors from stripe correctly
            this.processingPaymentMethod = false;
            this.cardInfoError = 'Please check your card details and try again.';
          } else {
            store.dispatch('setPaymentInfo', { paymentMethod: method, paymentMethodObj: paymentMethodResult.paymentMethod }); // maybe pass this instead to confirmOrder?
            await this.$store.dispatch('confirmOrder');
            this.$emit('transactionCompleted');
          }

        } else {
          EventBus.$emit('trackTransactionAttempt', { bundle: this.bundle, method });
  
          return paymentHelper.createToken({
            stripe,
            card,
            store,
            apiClient,
            bundle,
            method,
          }).then((data) => {
            return data;
          })
            .then(data => paymentHelper.createCustomer(data)
            .then(paymentHelper.placeOrder)
            .then(() => {
              EventBus.$emit('trackPurchase', {
                order: this.order,
                orderNumber: this.confirmation.orderNumber,
                isNewCustomer: this.confirmation.isNewCustomer,
                bundle: this.bundle,
                method,
              });
              this.$store.commit('reset');
              this.$emit('transactionCompleted', this.confirmation);
            }))
            .catch((err) => {
              if (err) {
                this.cardInfoError = err.message;
              }
            })
            .then(() => {
              this.processingPaymentMethod = false;
            });
        }
      },
    },

  };
</script>
<style scoped lang="scss">

  .title {
    margin: 0;
    line-height: 0;
  }

  .pm-title {
    display: block;
    margin: 20px 0 20px;
  }

  .checkout_form-creditcard-elements {
    padding: 0 0;
  }

  /**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */

  .StripeElement {
    background-color: white;
    padding: 12px 12px;
    border: 1px solid $color-light-gray;
    border-radius: $input-radius;
  }

  .StripeElement--focus {
    border-color: #000;
  }

  .StripeElement--invalid {
    border-color: $color-accent;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  #card-errors2 {
    color: $color-accent;
    font-family: $font-stack-light;
    margin-top: 10px;
  }

  .checkout_error {
    color: $color-accent;
  }

  .terms {
    font-size: 14px;
    line-height: 1.25;
    letter-spacing: 0;
    margin: 20px 0 0;
  }

  .change-method {
    text-decoration: underline;
  }

  .btn-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;

    @media #{$tablet} {
      flex-direction: row;
    }

    @media #{$desktop} {
      flex-direction: row;
    }
  }

  .btn {
    margin: 20px 0;

    @media #{$tablet} {
      width: auto;
    }

    @media #{$desktop} {
      width: auto;
    }
  }

  .checkout_processing {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .checkout_processing_item {
    padding-left: 10px;
  }
</style>
