<template>
  <div :id="$options.name" class="checkout-shippingmethods">
    <div class="checkout-shippingmethods-headline">
      <h3 v-track-view="$options.name">
        Shipping Method*
      </h3>
    </div>
    <ShippingMethodBlock
      id="StandardShipping"
      :class="{ 'checkout-shippingmethods-processing': processingTotalCost}"
      title="Standard Shipping"
      subtitle="10-15 business days"
      :price="shippingPrice"
      :show-discount="showDiscountShipping"
      :active="true" />
    <p class="checkout-shippingmethods-error">
      {{ shippingMethodError.message }}
    </p>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { ShippingMethodTypes } from '@/shared/constants/ShippingMethodTypes.js'
  import ShippingMethodBlock from './Checkout_ShippingMethodBlock.vue';
  import ShippingModal from './Checkout_ShippingModal.vue';

  export default {
    name: 'Checkout_ShippingMethods',
    components: {
      ShippingMethodBlock,
    },
    data: () => ({
      isShippingDelayed: false,
    }),
    props: {
      method: {
        type: String,
        default: ShippingMethodTypes.STANDARD,
        validator(method) {
          const types = Object.values(ShippingMethodTypes);
          return types.includes(method);
        }
      }
    },
    computed: {
      ...mapGetters([
        'order',
        'processingTotalCost',
        'shippingMethod',
        'shippingMethodError',
        'bundle',
        'summary_shipping',
      ]),
      isTribe() {
        return this.bundle.find(item => ['tribe_LL', 'tribe_SS', 'tribe_SL'].includes(item.id));
      },
      isPersonal() {
        return this.bundle.find(item => ['personal_S', 'personal_L'].includes(item.id));
      },
      showDiscountShipping() {
        return !!this.isPersonal || !!this.isTribe;
      },
      shippingPrice() {
        return '$'+ parseFloat((this.summary_shipping / 100.0).toString()).toFixed(0)
      }
    },
    methods: {
      openModal() {
        this.$modal.show(
          ShippingModal,
          {},
          {
            adaptive: true,
            height: 'auto',
            width: '95%',
            maxWidth: 700,
          },
          {},
        );
      },
    },
  };
</script>
<style scoped lang="scss">
.checkout-shippingmethods {
  padding: 8px;

  @media #{$tablet} {
    padding: 16px;
  }

  @media #{$desktop} {
    padding: 16px;
  }

  &-headline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &-learnmore {
    text-decoration: underline;
  }

  &-processing {
    cursor: progress !important;
  }

  &-error {
    color: $color-red;
  }

  &-notes {
    margin: 10px 0;
    font-size: 14px;
    color: $color-dark-gray-1;

    @media #{$tablet} {
      font-size: 16px;
    }

    @media #{$desktop} {
      font-size: 16px;
    }
  }
}
</style>
