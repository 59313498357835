<script>
  import Email from '@/shared/components/checkout/Checkout_Email.vue';
  import { FCInput } from '@fc/angie-ui';
  import storeHelpers from '@/shared/store/helpers';
  import Shipping from '@/shared/components/checkout/Checkout_Shipping.vue';
  import Payment from '@/shared/components/checkout/Checkout_Payment.vue';
  import PaymentMethodCreditCard from '@/shared/components/checkout/Checkout_PaymentMethodCreditCard.vue';
  import PaymentMethodPrepayAffirmTeleSale from '@/shared/components/checkout/Checkout_PaymentMethodAffirmTeleSale.vue';
  import { mapGetters } from 'vuex';
  import debounce from 'lodash/debounce';
  import ShopifyMembershipTypes from './ShopifyMembershipTypes.vue';
  import CartDisplayShopify from './CartDisplayShopify.vue';
  import ShopifyShippingMethods from './ShopifyShippingMethods.vue';

  export default {
    name: 'ShopifyCheckoutForm',
    components: {
      Email,
      FCInput,
      Shipping,
      Payment,
      CartDisplayShopify,
      ShopifyMembershipTypes,
      ShopifyShippingMethods,
    },
    data: () => ({
      paymentMethods: [
        {
          cid: 'creditcard_cid',
          component: PaymentMethodCreditCard,
          merchant: 'stripe',
        },
        {
          cid: 'prepay_affirm_cid',
          component: PaymentMethodPrepayAffirmTeleSale,
          merchant: 'affirm',
        },
      ],
      filledOnce: false, // is this right?
    }),
    computed: {
      ...mapGetters([
        'shipping',
        'cartqty',
        'isShippingFilled',
        'isContactFilled',
        'cartCoupon',
        'order',
        'isSubsInCart',
        'summary_shipping',
        'isPosOn',
      ]),
      ...storeHelpers.mapInputBinding(['receiptEmail']),
      checkFormFilled() {
        return this.isShippingFilled && this.isContactFilled;
      },
      shippingMethod() {
        const [startDay, endDay] = this.$store.state.checkout.shippingDays;
        const dates = `${this.addBusinessDays(startDay)} - ${this.addBusinessDays(endDay)}`;

        return {
          price: this.displayPrice,
          title: 'Standard Delivery',
          dates,
          showModalButton: false,
        };
      },
      displayPrice() {
        if (!this.summary_shipping) return '';

        return this.$options.filters.currency(this.summary_shipping);
      },
    },
    methods: {
      completed() {
        this.$store.commit('clearShipping');
        this.$store.commit('clearContact');
        this.$router.push({ path: '/bizDev/confirmation/' });
      },
      refreshForm() {
        if ((this.checkFormFilled || this.filledOnce) && this.cartqty > 0) {
          this.filledOnce = true;
          this.debounce();
        } else {
          this.$store.commit('clearShippingAddress');
        }
      },
      debounce: debounce(
        // eslint-disable-next-line func-names
        function () {
          this.$store.commit('clearShippingAddress');
          Promise.resolve()
            .then(this.processTotalCost)
            .catch((err) => {});
        },
        500,
      ),
      addBusinessDays(days) {
        const date = new Date();
        const d = new Date(date.getTime());
        const day = d.getDay();
        const final = d.setDate(d.getDate() + days + (day === 6 ? 2 : +!day) + (Math.floor((days - 1 + (day % 6 || 1)) / 5) * 2));
        const f = new Date(final);
        const month = f.toLocaleString('default', { month: 'long' });

        const x = f.getDate();
        return `${month} ${x}`;
      },
      async onSubmit(handler) {
        this.filledOnce = true;
        if (!handler) {
          return;
        }
        if (this.order) {
          const shippingMethodError = await this.$store.dispatch('validateShippingMethod');
          if (shippingMethodError) {
            return;
          }

          await Promise.resolve(handler()).then(() => {
            this.$store.dispatch('sendReceiptEmail');
          }).catch((err) => {
            console.log(err);
          });
        } else if (!this.processingTotalCost) {
          await Promise.resolve(true)
            .then(this.processTotalCost)
            .catch((err) => {});
        }
      },
      async processTotalCost() {
        const contactError = await this.$store.dispatch('validateContact');
        const shippingError = await this.$store.dispatch('validateShipping');
        if (contactError) {
          return;
        }
        if (shippingError) {
          return;
        }

        const checkoutId = this.$store.state.checkout.checkoutId;
        let order;
        if (checkoutId) {
          order = await this.$store.dispatch('updateCheckout', { shippingDetails: this.shipping, eCommService: this.$eCommService });
        } else {
          order = await this.$store.dispatch('createCheckout', { shippingDetails: this.shipping, eCommService: this.$eCommService });
        }

        if (order) {
          this.$analytics.nameIdentified(this.shipping.firstName, this.shipping.lastName);
          this.$analytics.phoneNumberIdentified(this.shipping.phoneNumber);
        }
      },
    },
    watch: {
      shipping: {
        handler() {
          this.refreshForm();
        },
        deep: true,
      },
      contact: {
        handler() {
          this.refreshForm();
        },
        deep: true,
      },
    },
  };
</script>

<template>
  <div
    id="checkout_cart"
    class="checkout"
    :class="{ checkout_container__active: true}">
    <div class="checkout-row">
      <div class="checkout-dynamic">
        <Email />
        <div v-if="!isPosOn" class="fc-padding-x-4">
          <FCInput
            v-model="receiptEmail"
            type="email"
            title="Company/Partner Email"
            name="receiptEmail"
            :value="receiptEmail" />
        </div>
        <Shipping />
        <ShopifyShippingMethods
          v-if="displayPrice"
          :title="shippingMethod.title"
          :show-modal-button="shippingMethod.showModalButton"
          :est-date="shippingMethod.dates"
          :price="shippingMethod.price" />
        <ShopifyMembershipTypes v-if="isSubsInCart" />
        <Payment
          :payment-methods="paymentMethods"
          @onSubmit="onSubmit($event)"
          @transactionCompleted="completed()" />
      </div>
      <div class="checkout-summary">
        <div class="checkout-summary-panel fc-padding-x-8">
          <h2 class="checkout-summary-title">
            Order Summary
          </h2>
          <CartDisplayShopify :is-drawer="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .checkout {
    &-cartbar {
      @media #{$desktop} {
        display: none;
      }
    }

    &-row {
      position: relative;
      display: flex;
      flex-direction: row;
      max-width: $page-width;
      margin: 20px auto;
      justify-content: center;
      align-items: flex-start;
    }

    &-processing {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-processing-title {
      margin: 10px;
    }

    &-dynamic {
      flex: 0 1 600px;
    }

    &-summary {
      display: none;
      flex: 1 1 600px;

      @media #{$desktop} {
        display: flex;
      }

      &-panel {
        width: 100%;
        text-align: center;
        color: $color-cart-text;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      &-title {
        margin: 30px 0 10px;
      }

      &-cart {
        max-width: 400px;
      }
    }

    &-cartdrawer {
      @media #{$desktop} {
        display: none;
      }
    }

    &::v-deep {
      .checkout__clickable {
        display: none;
      }
    }
  }
</style>
