<script>
  import { FCButton } from '@fc/angie-ui';

  export default {
    name: 'CartItemShopify',
    components: {
      FCButton,
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
      enableRemove: Boolean,
    },
    computed: {
      isIncludedItems() {
        return this.item?.items?.length > 0 ?? false;
      },
      totalItemPrice() {
        return this.item.price * this.item.qty;
      },
      prices() {
        const price = (this.item.price) * this.item.qty;
        const oldPrice = this.item?.product?.oldPrice ?? null;
        return { price, oldPrice };
      },
    },
  };
</script>

<template>
  <div class="fc-flex">
    <p class="cart-item__qty fc-font-size-5 fc-bold fc-margin-0">
      {{ item.qty }}
    </p>
    <div class="cart-item__info fc-flex fc-column fc-justify-start fc-padding-bottom-2 fc-text-left">
      <p class="fc-inline-block fc-bold fc-padding-bottom-2 fc-font-size-5 fc-margin-0">
        {{ item.productName }}
      </p>
      <p class="cart-item__description fc-inline-block fc-font-size-4 fc-text-blue fc-margin-0">
        {{ item.pricingDescription }}
      </p>
      <p v-if="isIncludedItems" class="cart-item__include-title fc-text-blue fc-margin-0 fc-padding-top-1 fc-padding-right-3">
        Includes:
      </p>
      <ul v-if="isIncludedItems" class="cart-item__include-list fc-padding-0 fc-margin-0 fc-font-size-4">
        <li
          v-for="includedItem in item.items"
          :key="includedItem.name"
          class="cart-item__include-li fc-text-blue fc-padding-0 fc-margin-y-1">
          {{ includedItem.name }}
        </li>
      </ul>
    </div>
    <div class="fc-flex fc-column fc-align-end">
      <p v-if="prices.price" class="fc-text fc-inline-block fc-text-right fc-uppercase fc-font-size-5">
        <span>{{ prices.price | currency }}</span>
        <s v-if="prices.oldPrice">{{ prices.oldPrice| currency }}</s>
      </p>
      <FCButton
        v-if="enableRemove"
        button-theme="button-text"
        custom-event-name="removeItem"
        v-track-click="`${$options.name}_RemoveItem`"
        @removeItem="$emit('removeFromCart', item)">
        <p class="cart-item__remove-button fc-text fc-text-blue fc-underline fc-padding-left-1 fc-font-size-4">
          Remove
        </p>
      </FCButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$text-opacity: 0.73;

.cart-item {
  &__qty {
    flex: 0 0 20px;

    @media #{$small-device-up} {
      flex: 0 0 40px;
    }
  }

  &__info {
    flex: 1 1 300px;
  }

  &__description {
    font-family: $font-stack-light;
    font-size: get-font-size-level(4);
    opacity: $text-opacity;

    @media #{$small-device-up} {
      font-size: get-font-size-level(5);
    }
  }

  &__include-title {
    font-size: get-font-size-level(4);
    opacity: $text-opacity;

    @media #{$small-device-up} {
      font-size: get-font-size-level(5);
    }
  }

  &__include-list {
    opacity: $text-opacity;
  }

  &__include-li {
    font-size: get-font-size-level(4);
    list-style-type: none;

    @media #{$small-device-up} {
      font-size: get-font-size-level(5);
    }
  }

  &__include-li::before {
    content: "\2013\a0";
  }

  &__remove-button {
    opacity: $text-opacity;
  }
}
</style>
