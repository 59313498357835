<template>
  <transition name="fade">
    <div class="checkout-payment-container">
      <h3>Payment Method</h3>
      <div class="checkout-payment-text">
        <font-awesome-icon
          :icon="['fas', 'lock']"
          class="checkout-payment-lock-icon"
          aria-hidden="true" />
        <p>All transactions are secure and encrypted. </p>
      </div>
      <Collapsible
        v-for="method in paymentMethods"
        :key="method.cid"
        :cid="method.cid"
        :state="isMethodShowing(method.cid) ">
        <component
          :is="method.component"
          :active="selectedMethod === method.cid"
          v-track-click="selectedMethod"
          @onSelected="toggleMethod(method)"
          @onSubmit="$emit('onSubmit', $event)"
          @transactionCompleted="$emit('transactionCompleted', $event)" />
      </Collapsible>
      <p class="checkout-payment-terms">
        By completing my purchase, I confirm that I have read and agree to the FightCamp
        <a href="/pages/legal-terms" target="_blank">Terms of Service</a> and
        <a href="/pages/privacy-policy" target="_blank"> Privacy Policy</a>, and
        that I understand that my membership will automatically renew at the current subscription rate until I pause or cancel.
      </p>
    </div>
  </transition>
</template>

<script>
  import PaymentMethod from './Checkout_PaymentMethod.vue';
  import Collapsible from '@/shared/components/CollapsiblePanel.vue';

  export default {
    name: 'Checkout_Payment',
    components: {
      PaymentMethod,
      Collapsible,
    },
    props: {
      paymentMethods: {
        type: Array,
        default: () => ([]),
      },
    },
    data: () => ({
      selectedMethod: '',
    }),
    watch: {
      selectedMethod() {
        this.$store.commit('updateStripeToken', null);
      },
    },
    methods: {
      toggleMethod(method) {
        if (this.selectedMethod !== method.cid) {
          this.selectedMethod = method.cid;
        } else {
          this.selectedMethod = '';
        }
      },
      isMethodShowing(method) {
        return this.selectedMethod === method || this.selectedMethod === '';
      },
    },
  };
</script>
<style scoped lang="scss">
.checkout-payment {
  &-container {
    padding: 8px;

    @media #{$tablet} {
      padding: 16px;
    }

    @media #{$desktop} {
      padding: 16px;
    }
  }

  &-text {
    display: flex;
    align-items: center;

    p {
      margin-left: 0.5rem;
    }
  }

  &-terms {
    text-align: center;
    font-size: 0.8125rem;

    @media #{$desktop} {
      text-align: left;
    }
  }
}
</style>
