<template>
  <div class="shipping-block fc-flex fc-row fc-justify-between fc-align-center" v-track-view="title">
    <div v-if="processingTotalCost" class="shipping-block__txshp-processing fc-flex fc-align-center fc-justify-center">
      <font-awesome-icon
        :icon="['fas', 'circle-notch']"
        spin
        class="shipping-block__spinner" />
    </div>
    <div class="shipping-block-text fc-flex fc-column fc-justify-center">
      <p class="fc-bold fc-margin-0 fc-text-blue">
        {{ title }}
        <button
          v-if="showModalButton"
          type="button"
          aria-label="More Information"
          class="shipping-block__info"
          v-track-click="trackTitle"
          @click="openModal()">
          <sup>ⓘ</sup>
        </button>
        <span v-if="price.length !== 0"> {{ price }}</span>
      </p>
      <p class="fc-text-blue fc-margin-0 fc-font-size-5">
        {{ getDateCopy }}
      </p>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { Actions } from '@/shared/store/actionsTypes';

  // TODO copied from Shop ShippingBlock component, combine so both use this
  export default {
    name: 'ShopifyShippingBlock',
    props: {
      title: {
        type: String,
        required: true,
      },
      showModalButton: {
        type: Boolean,
        default: false,
      },
      estDate: {
        type: String,
        required: true,
      },
      price: {
        type: String,
        required: true,
      },
    },
    computed: {
      trackTitle() {
        return `${this.title}_InfoButton`;
      },
      getDateCopy() {
        return `Estimated Arrival Date: ${this.estDate}`;
      },
      ...mapGetters([
        'processingTotalCost',
      ]),
    },
    methods: {
      openModal() {
        this.$store.dispatch(Actions.OPEN_MODAL, { component: '' });
      },
    },
  };
</script>

<style scoped lang="scss">
@import '@fc/angie-ui/dist/styles/exports';

.shipping-block {
  border: 1px solid $color-light-gray;
  border-radius: $input-radius;
  padding: 0.625rem;
  min-height: 6.875rem;
  margin: 5px 0;
  position: relative;

  &__info {
    background: transparent;
    border: none;
    color: $color-red;
    padding: 0;
  }

  &__txshp-processing {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: -0.0625rem;
  }

  &__spinner {
    color: $color-red;
    font-size: 1.5rem;
  }
}
</style>
