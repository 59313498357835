<template>
  <div class="fc-flex-row-between fc-bg-eggshell fc-padding-2 fc-margin-3 row">
    <p class="fc-margin-0 title">
      {{ displayName }}
    </p>
    <p class="price">
      <s v-if="(!item.subscriptionNeeded || item.id.startsWith('holiday')) && item.product.oldPrice">{{ item.product.oldPrice | currency }}</s>  {{ item.price | currency }}
    </p>
    <ButtonAction
      primary
      size="small"
      class="fc-margin-0"
      @click.native="addItemToCart()">
      {{ cta }}
    </ButtonAction>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import products from '@/shared/datasets/variants';
  import ButtonAction from '@/shared/components/ButtonAction.vue';

  const worksSizeMap = new Map([
    ['the_works_SS', ['trackers_with_bag_S', 'fightcamp_bundle_ultimate_S']],
    ['the_works_LL', ['trackers_with_bag_L', 'fightcamp_bundle_ultimate_L']],
    ['the_works_SL', ['trackers_with_bag_S', 'fightcamp_bundle_ultimate_L']],
    ['the_works_LS', ['trackers_with_bag_L', 'fightcamp_bundle_ultimate_S']],
  ]);

  const holidaySizeMap = new Map([
    ['fightcamp_holiday_bundle_SSSS', 'All in One Bundle, 2 Small Quick Wraps & 2 Small Pair of Gloves'],
    ['fightcamp_holiday_bundle_SSSL', 'All in One Bundle, 2 Small Quick Wraps & 1 Small Pair of Gloves & 1 Large Pair of Gloves'],
    ['fightcamp_holiday_bundle_SSLL', 'All in One Bundle, 2 Small Pair of Quick Wraps & 2 Large Pair of Gloves'],
    ['fightcamp_holiday_bundle_SLSS', 'All in One Bundle, 1 Small Pair of Quick Wraps & 1 Large Pair of Quick Wraps & & 2 Pair of Gloves'],
    ['fightcamp_holiday_bundle_SLSL', 'All in One Bundle, 1 Small Pair of Quick Wraps & 1 Large Pair of Quick Wraps & 1 Small Pair of Gloves & 1 Large Pair of Gloves'],
    ['fightcamp_holiday_bundle_SLLL', 'All in One Bundle, 1 Small Pair of Quick Wraps & 1 Large Pair of Quick Wraps & 2 Large Pair of Gloves'],
    ['fightcamp_holiday_bundle_LSSS', 'All in One Bundle, 2 Large Pair of Quick Wraps & 2 Small Pair of Gloves'],
    ['fightcamp_holiday_bundle_LSSL', 'All in One Bundle, 2 Large Pair of Quick Wraps & 1 Small Pair of Gloves & 1 Large Pair of Gloves'],
    ['fightcamp_holiday_bundle_LLSS', 'All in One Bundle, 2 Large Quick Wraps & 2 Small Pair of Gloves'],
    ['fightcamp_holiday_bundle_LLSL', 'All in One Bundle, 2 Large Quick Wraps & & 1 Small Pair of Gloves & 1 Large Pair of Gloves'],
    ['fightcamp_holiday_bundle_LLLL', 'All in One Bundle, 2 Large Quick Wraps & 2 Large Pair of Gloves'],
  ]);

  export default {
    name: 'Item',
    components: {
      ButtonAction,
    },
    props: {
      product: { type: String, default: '' },
    },
    computed: {
      ...mapGetters([
        'packageInCart',
        'hasPackageWithBag',
      ]),
      item() {
        return products.find(product => product.id === this.product);
      },
      displayName() {
        if (holidaySizeMap.has(this.product)) {
          return holidaySizeMap.get(this.product);
        }
        return this.item.productName.replace('FightCamp ', '');
      },
      cta() {
        if (this.hasItemInCartWithSub) {
          return 'Replace in Cart';
        }
        return 'Add To Cart';
      },
      hasItemInCartWithSub() {
        return this.packageInCart && this.item.subscriptionNeeded;
      },
    },
    methods: {
      async addItemToCart() {
        if (this.hasItemInCartWithSub) {
          this.$store.commit('removeItemFromCart', this.packageInCart);
        }

        if (worksSizeMap.has(this.product)) {
          const items = worksSizeMap.get(this.product);

          items.forEach(item => {
            this.$store.commit('insertItemToCart', {
              id: item,
              type: 'equip',
            });
          });
        } else {
          this.$store.commit('insertItemToCart', {
            id: this.product,
            type: 'equip',
          });
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@fc/angie-ui/dist/styles/exports';

  .row {
    border-radius: $container-radius;
  }

  .title,
  .price {
    font-size: get-font-size-level(6);
  }

  .title {
    flex: 0 1 200px;
  }

  .price {
    flex: 0 1 70px;
  }
</style>
