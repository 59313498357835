<template>
  <div>
    <div v-if="isShopifyOn" class="fc-container-lg">
      <div class="fc-flex fc-row">
        <div
          v-for="items in transformedCollection"
          :key="items[0].id"
          class="items-column">
          <h2>
            {{ items[0].productType }}
          </h2>
          <ShopifyItem
            v-for="item in items"
            :key="item.id"
            :product="item" />
        </div>
      </div>
    </div>
    <div v-else class="fc-flex fc-row">
      <div class="items-column">
        <h2>
          BPI Packages
        </h2>
        <Item
          v-for="p in bpi"
          :key="p"
          :product="p" />
        <h2>
          Old Packages
        </h2>
        <Item
          v-for="pack in packages"
          :key="pack"
          :product="pack" />
        <h2 style=" text-align: center;">
          Bundles
        </h2>
        <Item
          v-for="bundle in bundles"
          :key="bundle"
          :product="bundle" />
        <h2 style=" text-align: center;">
          Biz Dev Deal Items
        </h2>
        <Item
          v-for="deal in bizDevDealItems"
          :key="deal"
          :product="deal" />
        <h2 style=" text-align: center;">
          Gift Items
        </h2>
        <Item
          v-for="gift in gifts"
          :key="gift"
          :product="gift" />
      </div>
      <div class="items-column">
        <h2>
          Accessories
        </h2>
        <Item
          v-for="accessory in accessories"
          :key="accessory"
          :product="accessory" />
      </div>
      <div class="items-column">
        <h2>
          Warranties
        </h2>
        <Item
          v-for="warranty in warranties"
          :key="warranty"
          :product="warranty" />
      </div>
    </div>
    <ShopifyCheckoutForm v-if="isShopifyOn" />
    <CheckoutForm v-else />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import CheckoutForm from './CheckoutForm.vue';
  import ShopifyCheckoutForm from './ShopifyCheckoutForm.vue';
  import Item from './Item.vue';
  import ShopifyItem from './ShopifyItem.vue';

  export default {
    name: 'Checkout',
    components: {
      CheckoutForm,
      Item,
      ShopifyItem,
      ShopifyCheckoutForm,
    },
    data() {
      return {
        bpi: [
          'trackers_S',
          'trackers_L',
          'trackers_with_bag_S',
          'trackers_with_bag_L',
          'the_works_SS',
          'the_works_LL',
          'the_works_SL',
          'the_works_LS',
          'fightcamp_holiday_bundle_SSSS',
          'fightcamp_holiday_bundle_SSSL',
          'fightcamp_holiday_bundle_SSLL',
          'fightcamp_holiday_bundle_SLSS',
          'fightcamp_holiday_bundle_SLSL',
          'fightcamp_holiday_bundle_SLLL',
          'fightcamp_holiday_bundle_LSSS',
          'fightcamp_holiday_bundle_LSSL',
          'fightcamp_holiday_bundle_LLSS',
          'fightcamp_holiday_bundle_LLSL',
          'fightcamp_holiday_bundle_LLLL',
        ],
        packages: [
          'personal_S',
          'personal_L',
          'tribe_SS',
          'tribe_SL',
          'tribe_LL',
          'personal_L_snappy',
          'tribe_SL_snappy',
        ],
        accessories: [
          'customGloves_V2_S',
          'customGloves_V2_L',
          'customGloves_V2_K',
          'quickWraps_V2_S',
          'quickWraps_V2_L',
          'quickWraps_3p_SSS',
          'quickWraps_3p_LLL',
          'quickWraps_3p_SSL',
          'quickWraps_3p_LLS',
          'freeStandingBag_V2',
          'fightcamp_bag_ring_bpi',
          'fightcamp_premium_flooring',
          'fightcamp_yoga_mat',
          'fightcamp_heart_rate_monitor',
          'fightcamp_water_bottle',
          'fightcamp_resistance_bands',
          'fightcamp_jump_rope',
          'fightcamp_laundry_bag',
          'fightcamp_glove_freshener',
          'fightcamp_glove_bag',
          'fightcamp_hdmi_cable',
          'fightcamp_shi_apple_cable',
        ],
        bundles: [
          'fightcamp_bundle_protect_floor',
          'fightcamp_bundle_keep_fresh_SSS',
          'fightcamp_bundle_keep_fresh_SSL',
          'fightcamp_bundle_keep_fresh_SLL',
          'fightcamp_bundle_keep_fresh_LLL',
          'fightcamp_bundle_tv',
          'fightcamp_family_bundle',
        ],
        bizDevDealItems: [
          'customGloves_V2_S_deal',
          'customGloves_V2_L_deal',
        ],
        warranties: [
          'fightcamp_trackers_ext_warranty_19',
          'fightcamp_trackers_bag_ext_warranty_79',
        ],
        gifts: [
        ],
        isBizDevOn: true,
      };
    },
    computed: {
      ...mapGetters([
        'isShopifyOn',
        'transformedCollection',
      ]),
    },
  };
</script>

<style lang="scss">
  .items-column {
    flex: 0 1 50%;

    h2 {
      text-align: center;
    }
  }

.checkout_container {
  display: none;
  align-self: center;
  box-sizing: border-box;
  flex-direction: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.checkout_container__active {
  display: flex;
}

.checkout_summary,
.checkout_dynamic {
  box-sizing: border-box;
  padding: 16px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.checkout_summary {
  order: 2;
}

.checkout_dynamic {
  margin-top: 24px;
  order: 1;
}

@media all and (min-width: 1000px) {
  .checkout_container {
    flex-direction: row;
  }

  .checkout_dynamic {
    order: 2;
    width: 50%;
  }

  .checkout_summary {
    width: 50%;
    margin-top: 24px;
    order: 1;
  }
}

.checkout_row {
  width: 100%;
}

.checkout_text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkout__clickable {
  cursor: pointer;
}

a.checkout__clickable,
p.checkout__clickable {
  text-decoration: underline;
}

.checkout_btn-row {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}
</style>
