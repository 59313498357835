<template>
  <img :src="affirmLogo" alt="Affirm">
</template>

<script>
export default {
  name: 'AffirmLogo',
  props: {
    color: String,
    required: true,
  },
  data() {
    return {
      logo: {
        'white': 'https://cdn-assets.affirm.com/images/white_logo-solid_bg.svg',
        'indigo': 'https://cdn-assets.affirm.com/images/black_logo-white_bg.svg',
      }
    }
  },
  computed: {
    affirmLogo() {
      return this.logo[this.color]
    }
  }
}
</script>
