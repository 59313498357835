<template>
  <transition name="fade">
    <div class="checkout_form">
      <div class="checkout_form-email-container">
        <div class="checkout_text">
          <h3> Contact Info </h3>
          <a
            class="checkout__clickable"
            v-track-click="`${$options.name}_Logout`"
            @click="$emit('signOut')">
            Logout
          </a>
        </div>
        <FormInput
          id="contactEmail"
          v-model="contactEmail"
          class="fc-input"
          type="text"
          name="email"
          autocomplete="off"
          title="Email"
          :lowercase-only="true"
          :error="contactError.element === 'contactEmail'"
          @blur="validate()" />
        <div class="checkout_phone">
          <FormInput
            id="contactPhoneNumber"
            v-model="contactPhoneNumber"
            class="fc-input"
            type="tel"
            name="shippingPhoneNumber"
            autocomplete="tel"
            title="Phone Number"
            :error="contactError.element === 'contactPhoneNumber'"
            @blur="validate()"
            @focus="phoneNumbeError=false" />
          <p class="error-msg">
            {{ contactError.message }}
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import {
    mapGetters,
  } from 'vuex';
  import FormInput from '../FCInput.vue';
  import storeHelpers from '@/shared/store/helpers';


  export default {
    name: 'Checkout_Email',
    components: {
      FormInput,
    },
    computed: {
      ...mapGetters(['currentUser', 'cart', 'coupon', 'contactError']),
      ...storeHelpers.mapInputBinding(['contactEmail', 'contactPhoneNumber']),
    },
    methods: {
      validate() {
        // this.$store.dispatch('validateContact');
      },
    },
  };
</script>
<style scoped lang="scss">

  .checkout_form {
    padding: 8px;

    @media #{$tablet} {
      padding: 16px;
    }

    @media #{$desktop} {
      padding: 16px;
    }
  }

  .checkout_form-email-container {
    box-sizing: border-box;
    padding: 10px 0;
  }

  .checkout_form-email-container p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .checkout_form-email-container h3 {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 8px;
  }

  .checkout_form p {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .checkout_form h3 {
    padding-bottom: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .checkout_text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .checkout__clickable {
    cursor: pointer;
  }

  a.checkout__clickable,
  p.checkout__clickable {
    text-decoration: underline;
    color: $color-accent;
  }

  .error-msg {
    color: $color-accent;
    padding-left: 10px;
  }

  .fc-input {
    margin: 8px 0;
  }

</style>
